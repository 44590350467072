<template>
  <div class="office" @click="goDetails(itemData.id, itemData.type)">
    <img
      class="icon"
      :class="{ lawyer: sourceType === 'LAWYER' }"
      :src="this[sourceType]"
    />
    <div class="info">
      <el-popover
        v-if="showPopover"
        placement="top-start"
        width="210"
        trigger="hover"
        :content="itemData.name"
      >
        <div slot="reference" class="title">
          {{ itemData.name }}
          <span v-if="!itemData.type" class="rigister">已注册</span>
        </div>
      </el-popover>
      <div
        v-for="(content, index) of labelData"
        :key="index"
        class="uiText"
        :class="{ lastUiText: content.key === 'scopeOfBusiness' }"
      >
        {{ content.lable ? content.lable + ":" : "" }}
        {{ handleKey(itemData, content.key) | textFilter }}
      </div>
    </div>
    <div v-if="showButton" class="button">
      <a @click="handleButtonClick">
        <Icon :src="SERVICE_CALL" />
        {{ $t("249393c") }}
      </a>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { isLogin } from "@/utils/jurisdiction";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Office",
  components: {
    Icon,
  },
  props: {
    showPopover: {
      type: Boolean,
      required: true,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      required: true,
    },
    labelData: {
      type: Array,
      required: true,
    },
    sourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      LAWYER: require("@/assets/service/icon0.png"),
      LAW_OFFICE: require("@/assets/service/icon1.png"),
      ARBITRATION_BOARD: require("@/assets/service/icon2.png"),
      CONCILIATION_BOARD: require("@/assets/service/icon3.png"),
      NOTARY_OFFICE: require("@/assets/service/icon4.png"),
      JUDICIAL_EXPERTISE: require("@/assets/service/icon5.png"),
      LAW_HELPER: require("@/assets/service/icon6.png"),
      SERVICE_CALL: require("@/assets/service/service_call.png"),
    };
  },
  computed: {
    // ...mapGetters(['activeIndex']),
  },
  methods: {
    handleTeamSize(size) {
      let result = "";
      switch (true) {
        case size > 10000:
          result = "大于10000人";
          break;
        case size > 1000:
          result = `${Math.floor(size / 1000)}000-${Math.ceil(
            (size + 1) / 1000
          )}000人`;
          break;
        case size > 100:
          result = `${Math.floor(size / 100)}00-${Math.ceil(
            (size + 1) / 100
          )}00人`;
          break;
        case size > 10:
          result = `${Math.floor(size / 10)}0-${Math.ceil((size + 1) / 10)}0人`;
          break;
        default:
          result = `0-10人`;
      }
      return result;
    },
    handleKey(data, key) {
      let result = data[key];
      if (key === "teamSize") {
        result = this.handleTeamSize(Number(result));
      }
      return result;
    },
    handleButtonClick(e) {
      if (isLogin()) {
        this.$emit("buttonclick", this.itemData);
      } else {
        this.$router.push("/account/login");
      }
      e.stopPropagation();
    },
    goDetails(id, type) {
      const sourceType = this.sourceType;
      if (sourceType !== "LAWYER") {
        this.$router.push({
          path: "/service/affairs/details",
          query: {
            id: this.itemData.id,
            scId: this.itemData.scId,
            sourceType: this.sourceType,
            type: this.itemData.type,
          },
        });
        this.$parent.$parent.showPopover = false;
      } else {
        this.$router.push({
          path: `/communication/lawyer/detail?id=${id}&type=${type}&sorceType=lawer`,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
@import "~@/styles/common.less";
.button {
  position: absolute;
  right: 16px;
  top: 16px;
  a {
    color: black;
  }
  &:hover {
    a {
      color: #00a4ff;
    }
  }
}
.rigister {
  font-size: 14px;
  color: #51c518;
}
.office {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 387px;
  height: 212px;
  margin-bottom: 20px;
  padding: 26px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
  border: 1px solid #e5e8ed;
  cursor: pointer;
  .icon {
    width: 86px;
    height: 86px;
  }
  .lawyer {
    width: 86px;
    height: 116px;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 240px;
    margin-left: 20px;
    font-size: 14px;
    color: @uni-text-color-grey;
    .title {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      .uni-ellipsis;
    }
    .uiText {
      padding-top: 9px;
      .uni-ellipsis;
      // line-height: 21px;
    }
    .lastUiText {
      margin-top: 7px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 20px;
    }
  }
}
</style>
