<template>
  <div class="navigation">
    <div
      v-for="(item, index) of list"
      :key="index"
      class="uiText"
      :class="{ active: $route.path.includes(item.path) }"
      @click="changNav(item.path, index)"
    >
      <img :src="item.icon" alt="" class="uiImg" />
      <div>{{ $t(item.content) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Navigation",
  components: {},
  props: {},
  computed: {
    ...mapGetters(["activeIndex"]),
  },
  data() {
    return {
      list: [
        {
          content: "db860a1",
          icon: require("@/assets/service/switchIcon1.png"),
          path: "/service/lawyer",
        },
        {
          content: "5181186",
          icon: require("@/assets/service/switchIcon5.png"),
          path: "/service/arbitration",
        },
        {
          content: "82c0296",
          icon: require("@/assets/service/switchIcon4.png"),
          path: "/service/mediate",
        },
        {
          content: "41dbeea",
          icon: require("@/assets/service/switchIcon3.png"),
          path: "/service/notarization",
        },
        {
          content: "3bfe59a",
          icon: require("@/assets/service/switchIcon6.png"),
          path: "/service/appraisal",
        },
        {
          content: "9111ebe",
          icon: require("@/assets/service/switchIcon2.png"),
          path: "/service/legalAid",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["changeActiveIndex"]),
    changNav(path, index) {
      this.$router.push({
        path,
      });
    },
    search() {},
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
@import "~@/styles/common.less";
.navigation {
  display: flex;
  justify-content: space-between;
  height: 50px;
  // margin-top: 47px;
  padding-top: 60px;
  border-bottom: 1px solid #e5e8ed;
  .uiText {
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 30px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    .uiImg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .active {
    color: @uni-text-color-select;
    border-bottom: 1px solid @uni-text-color-select;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: @uni-text-color-select;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #f7f8fa;
    }
  }
}
</style>
