<template>
  <div class="home">
    <Nav />
    <ShowPosterCard
      v-if="searchInfo.showPosterCard"
      :card-key-world="searchInfo.sourceType"
    />
    <Search
      ref="search"
      class="search"
      :search-info="searchInfo"
      @search="getData"
    />
    <ConditionSelection
      v-loading="loading"
      :options="options"
      :search-info="searchInfo"
      @search="getData"
    />
    <ListContainer
      v-if="organizationList.content.length"
      v-loading="loading"
      :page-info="pageInfo"
      :data-list="organizationList.content"
      :label-data="labelData"
      :source-type="searchInfo.sourceType"
      :show-popover="showPopover"
      @changePage="changePage"
    />
    <Empty
      v-if="!loading && organizationList.content.length === 0"
      type="serviceContent"
    />
  </div>
</template>

<script>
import ListContainer from "@/components/ListContainer";
import Nav from "@/components/Nav";
import Search from "@/components/Search";
import { mapGetters, mapActions } from "vuex";
import ConditionSelection from "@/components/ConditionSelection";
import ShowPosterCard from "@/components/ShowPosterCard";
import Empty from "@/components/Empty/Factory";
export default {
  name: "Affairs",
  components: {
    Nav,
    Search,
    ConditionSelection,
    ShowPosterCard,
    ListContainer,
    Empty,
  },
  data() {
    return {
      loading: true,
      showPopover: true,
      searchInfo: {
        countryId: "",
        // provinceId: 370000000000,
        provinceId: "",
        provinceIndex: 0,
        areaId: "",
        keyword: "",
        sourceType: "LAW_OFFICE",
        showPosterCard: false,
        sortKey: "",
      },
      pageInfo: {
        currentPage: 1,
        size: 9,
        total: 1,
      },
      options: [],
      labelData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes("/details")) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next((vm) => {
      vm.changeLabelData(to, from);
    });
  },
  // activated() {},
  computed: {
    ...mapGetters([
      "dictionaryList",
      "affairsTreeList",
      "organizationList",
      "serviceAreaList",
    ]),
  },
  watch: {
    "searchInfo.sourceType": {
      handler(newVal) {
        if (newVal === "LAW_OFFICE" || newVal === "LAWYER") {
          this.handleProvinceList();
        }
      },
      // immediate: true,
    },
    "searchInfo.countryId": {
      handler(newVal) {
        this.changeOptions();
      },
      // immediate: true,
    },
    "searchInfo.provinceIndex": {
      handler(newVal) {
        this.changeProvince();
      },
      // immediate: true,
    },
    affairsTreeList(newVal) {
      if (newVal) {
        this.handleProvinceList();
      }
    },
    organizationList(newVal) {
      if (newVal) {
        this.pageInfo.total = newVal.page.total;
        this.loading = false;
      }
    },
  },
  activated() {
    // this.changeLabelData()
  },
  created() {
    // this.changeLabelData()
  },
  methods: {
    ...mapActions([
      "getOrganizationList",
      "getLawyerList",
      "getDictionaryList",
      "getAffairsTreeList",
      "getServiceAreaList",
    ]),
    changeProvince() {
      const index = this.searchInfo.provinceIndex;
      const rootList = this.affairsTreeList;
      if (index >= 0 && rootList[index]) {
        const list = rootList[index].children;
        this.options[2] = {
          name: "ec0a09a",
          key: "areaId",
          colse: false,
          list,
        };
      }
    },
    changeOptions() {
      let result = true;
      const countryId = this.searchInfo.countryId;
      if (countryId === 1 || !countryId) {
        result = false;
      }
      this.options[1].colse = result;
      this.options[2].colse = result;
      this.options[4].colse = result;
    },
    handleProvinceList() {
      const sourceType = this.searchInfo.sourceType;
      const cod = [
        "CONCILIATION_BOARD",
        "NOTARY_OFFICE",
        "JUDICIAL_EXPERTISE",
        "LAW_HELPER",
      ].includes(sourceType);
      const result = [
        {
          name: "47fef72",
          key: "countryId",
          colse: cod,
          list: this.dictionaryList.content,
          // list: [{ id: 1, name: '中国' }, { id: 2, name: '国外' }],
        },
      ];
      if (this.searchInfo.countryId === 1 || !this.searchInfo.countryId) {
        const list = this.affairsTreeList;
        result.push({
          name: "070e2f3",
          key: "provinceId",
          list: list,
          colse: false,
        });
        result.push({
          name: "ec0a09a",
          key: "areaId",
          colse: false,
          list: list[this.searchInfo.provinceIndex].children,
        });
      }

      // if (sourceType === "LAW_OFFICE") {
      //   result.push({
      //     name: "c167394",
      //     key: "sortKey",
      //     list: [
      //       {
      //         id: "",
      //         name: "默认",
      //       },
      //       {
      //         id: "team_size",
      //         name: "团队规模",
      //       },
      //       {
      //         id: "approvat_date",
      //         name: "成立时间",
      //       },
      //     ],
      //   });
      // }
      if (sourceType === "LAW_OFFICE" || sourceType === "LAWYER") {
        // 剔除中国以外涉外国家
        const foreignCountryList = this.dictionaryList.content.filter(
          (d) => d.id !== 1
        );
        result.push({
          name: "service.selectionList[0]",
          key: "serviceAreaId",
          list: this.serviceAreaList.content,
        });
        if (this.searchInfo.countryId === 1 || !this.searchInfo.countryId) {
          result.push({
            name: "service.selectionList[1]",
            key: "foreignCountryId",
            list: foreignCountryList,
          });
        }
      }
      this.options = result;
      console.log(this.searchInfo);
      this.searchInfo.showPosterCard = [
        "ARBITRATION_BOARD",
        "CONCILIATION_BOARD",
        "NOTARY_OFFICE",
        "LAW_HELPER",
      ].includes(sourceType);
    },
    getData(isSearch) {
      this.handleItme();
      let sourceType = this.searchInfo.sourceType;
      const sortKey = this.searchInfo.sortKey;
      let fuc = "getOrganizationList";
      if (sourceType === "LAWYER") {
        fuc = "getLawyerList";
        sourceType = "";
      }
      const obj = {
        page: isSearch ? 1 : this.pageInfo.currentPage,
        size: this.pageInfo.size,
        countryId: this.searchInfo.countryId,
        provinceId: this.searchInfo.provinceId,
        areaId: this.searchInfo.areaId,
        keyword: this.searchInfo.keyword,
        foreignCountryId: this.searchInfo.foreignCountryId,
        serviceAreaId: this.searchInfo.serviceAreaId,
        sourceType: sourceType,
      };
      if (sortKey) {
        let sortType = "DESC";
        if (sortKey === "approvat_date") {
          sortType = "ASC";
        }
        obj.sortKey = sortKey;
        obj.sortType = sortType;
      }
      this[fuc](obj);
    },
    changePage(val) {
      this.pageInfo.currentPage = val;
      this.getData();
    },
    resetInfo() {
      this.pageInfo = {
        currentPage: 1,
        size: 9,
        total: 1,
      };
    },
    resetData() {
      this.searchInfo = {
        countryId: "",
        // provinceId: 370000000000,
        foreignCountryId: undefined,
        serviceAreaId: undefined,
        provinceId: "",
        provinceIndex: 0,
        areaId: "",
        keyword: "",
        sourceType: "LAW_OFFICE",
        sortKey: "",
        showPosterCard: false,
      };
      this.pageInfo = {
        currentPage: 1,
        size: 9,
        total: 1,
      };
    },
    changeLabelData(to, from) {
      this.loading = true;
      this.showPopover = true;
      this.resetData();
      this.handleSourceType();
      if (from.query.sourceType !== this.searchInfo.sourceType) {
        this.getAreaData();
        this.resetInfo();
      }
      this.getData();
    },
    async getAreaData() {
      await this.getDictionaryList({
        type: "COUNTRY",
      });
      this.getServiceAreaList({
        type: "BUSINESS_TYPE",
      });
      this.getAffairsTreeList({
        sourceType: this.searchInfo.sourceType,
      });
    },
    handleSourceType() {
      const path = this.$route.path;
      const obj = {
        "/service/lawyer": "LAW_OFFICE",
        "/service/arbitration": "ARBITRATION_BOARD",
        "/service/mediate": "CONCILIATION_BOARD",
        "/service/notarization": "NOTARY_OFFICE",
        "/service/appraisal": "JUDICIAL_EXPERTISE",
        "/service/legalAid": "LAW_HELPER",
      };
      this.searchInfo.sourceType = obj[path];
    },
    handleItme() {
      const path = this.$route.path;
      const obj = {
        "/service/lawyer": [
          {
            lable: "联系电话",
            key: "telephone",
          },
          {
            lable: "联系邮箱",
            key: "mail",
          },
          {
            lable: "联系地址",
            key: "address",
          },
        ],
        "/service/arbitration": [
          {
            lable: "电话",
            key: "telephone",
          },
          {
            lable: "地址",
            key: "address",
          },
          {
            lable: "人数",
            key: "teamSize",
          },
        ],
        "/service/mediate": [
          {
            lable: "所属地区",
            key: "province",
          },
          {
            lable: "联系电话",
            key: "telephone",
          },
          {
            lable: "机构地址",
            key: "address",
          },
        ],
        "/service/notarization": [
          {
            lable: "电话",
            key: "telephone",
          },
          {
            lable: "地址",
            key: "address",
          },
          {
            lable: "执业证号",
            key: "licenseNumber",
          },
        ],
        "/service/appraisal": [
          {
            lable: "联系电话",
            key: "telephone",
          },
          {
            lable: "联系地址",
            key: "address",
          },
          {
            lable: "业务范围",
            key: "scopeOfBusiness",
          },
        ],

        "/service/legalAid": [
          {
            lable: "电话",
            key: "telephone",
          },
          {
            lable: "邮编",
            key: "postcode",
          },
          {
            lable: "地址",
            key: "address",
          },
        ],
      };
      let result = obj[path];
      if (this.searchInfo.sourceType === "LAWYER") {
        result = [
          {
            lable: "",
            key: "practiceOrganization",
          },
          {
            lable: "联系电话",
            key: "telephone",
          },
          {
            lable: "联系邮箱",
            key: "mail",
          },
          {
            lable: "服务领域",
            key: "serviceArea",
          },
          {
            lable: "涉外国家",
            key: "foreignCountry",
          },
        ];
      }
      this.labelData = result;
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.home {
  width: @uni-width;
  margin: 0 auto;
  background: #fff;
}
</style>
