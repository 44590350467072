<template>
  <div
    :class="{
      cardWrapper: true,
      oneCardWrapper: posterList[cardKeyWorld].length === 1,
    }"
  >
    <div
      v-for="(content, contentIndex) in posterList[cardKeyWorld]"
      :key="contentIndex"
      @click="linkJumps(content.jumpUrl)"
      class="posterCard"
      :style="{ backgroundImage: 'url(' + content.bacUrl + ')' }"
    >
      {{ $t(content.text) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowPosterCard",
  props: {
    cardKeyWorld: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      posterList: {
        ARBITRATION_BOARD: [
          {
            text: "互联网仲裁平台",
            jumpUrl: "http://wlzc.qdac.org/#/login?redirect=%2Fdashboard",
            bacUrl: require("@/assets/service/jumback/zc1.png"),
          },
          {
            text: "区块链电子证据平台",
            jumpUrl: "http://evidence.qdac.org/#/page",
            bacUrl: require("@/assets/service/jumback/zc2.png"),
          },
        ],
        CONCILIATION_BOARD: [
          {
            text: "多元化调解平台",
            jumpUrl: "https://www.yunodr.com/party/index",
            bacUrl: require("@/assets/service/jumback/tj.png"),
          },
        ],
        NOTARY_OFFICE: [
          {
            text: "申办公证",
            jumpUrl: "https://sdqdjz.egongzheng.com/",
            bacUrl: require("@/assets/service/jumback/gz1.png"),
          },
          {
            text: "中国知识产权公证服务",
            jumpUrl: "https://www.ipnotary.com/",
            bacUrl: require("@/assets/service/jumback/gz2.png"),
          },
        ],
        LAW_HELPER: [
          {
            text: "12348山东法网",
            jumpUrl: "http://sd.12348.gov.cn/channels/ch00630/",
            bacUrl: require("@/assets/service/jumback/fy.png"),
          },
        ],
      },
    };
  },
  methods: {
    linkJumps(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="less">
.cardWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  &.oneCardWrapper {
    display: block;
    .posterCard {
      width: 100%;
    }
  }
  .posterCard {
    width: 590px;
    height: 80px;
    border-radius: 10px;
    text-align: center;
    line-height: 80px;
    color: #fff;
    font-size: 32px;
    cursor: pointer;
  }
}
</style>