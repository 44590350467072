<template>
  <div class="listContainer">
    <div class="list">
      <Office
        v-for="(item, index) of dataList"
        :key="item.id"
        :item-data="item"
        :label-data="labelData"
        :source-type="sourceType"
        :show-button="sourceType === 'LAWYER'"
        :show-popover="showPopover"
        @buttonclick="onConsult"
      />
      <div
        v-for="(item, index) in row - (len % row)"
        v-if="len % row > 0"
        :key="item + index"
        class="office"
      ></div>
      <Pagination
        class="pagination"
        :page-info="pageInfo"
        @changePage="changePage"
      />
    </div>
    <ConsultModal
      v-if="consultVisible"
      :id="consultId"
      :visible="consultVisible"
      :type="type"
      @close="consultVisible = false"
    />
  </div>
</template>

<script>
import ConsultModal from "@/components/ConsultModal";
import Office from "./Office";

import Pagination from "@/components/Pagination";
export default {
  name: "ListContainer",
  components: {
    Office,
    Pagination,
    ConsultModal,
  },
  props: {
    showPopover: {
      type: Boolean,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    pageInfo: {
      type: Object,
      required: true,
    },
    labelData: {
      type: Array,
      required: true,
    },
    sourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: "LAWYER",
      consultId: undefined,
      consultVisible: false,
      row: 3,
    };
  },
  computed: {
    len() {
      return this.dataList.length;
    },
  },
  methods: {
    onConsult(data) {
      if (data.type) this.type = "GRAB_LAWYER";
      this.consultId = data.id;
      this.consultVisible = true;
    },
    changePage(val) {
      this.$emit("changePage", val);
    },
    handleItemData(item) {
      return this.$parent.handleItme(item);
      // this.$router.push({})
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
.listContainer {
  width: @uni-width;
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .office {
      width: 387px;
      height: 212px;
      margin-bottom: 20px;
    }
  }
  .pagination {
    width: 100%;
    margin-bottom: 40px;
  }
}
</style>
